import {
  Col,
  Row,
} from '../../containers/Announcement/Announcement_UtilsComponents';
import { Heading } from 'components/Wrapper.style';
import Button, { KIND } from 'components/Button/Button';
import { useToasts } from 'react-toast-notifications';
import { css } from '@emotion/css';

const flexCenter = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const alertTitle = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
`;

function DeleteToast({ row, titleForMessage, onDelete }: any) {
  const { removeAllToasts } = useToasts();
  return (
    <Row>
      <Heading className={alertTitle}>{titleForMessage}</Heading>
      <Col className={flexCenter}>
        <Row>
          <Col>
            <Button
              kind={KIND.tertiary}
              onClick={() => removeAllToasts()}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    borderTopLeftRadius: '3px',
                    borderTopRightRadius: '3px',
                    borderBottomRightRadius: '3px',
                    borderBottomLeftRadius: '3px',
                    marginRight: '10px',
                    color: $theme.colors.red400,
                  }),
                },
              }}
            >
              Hayır
            </Button>
          </Col>
          <Col>
            <Button
              kind={KIND.tertiary}
              onClick={async (e: any) => {
                e.preventDefault();
                onDelete(row);
              }}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => ({
                    borderTopLeftRadius: '3px',
                    borderTopRightRadius: '3px',
                    borderBottomRightRadius: '3px',
                    borderBottomLeftRadius: '3px',
                  }),
                },
              }}
            >
              Evet
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default DeleteToast;
