import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query getCategories($_ilike: String = "%%", $limit: Int, $offset: Int) {
    blog_categories(
      where: { name: { _ilike: $_ilike }, is_deleted: { _eq: false } }
      limit: $limit
      offset: $offset
      order_by: { order: asc }
    ) {
      id
      name
      slug
      order
      status
      updated_at
      is_deleted
      created_at
      seo_title
      seo_description
      seo_keywords
    }
  }
`;

export type TreeCategoryType = {
  id: any;
  name: any;
  title: any;
  parent_id: any;
  order: any;
};
export type TreeCategoryChildrenType = {
  id: any;
  name: any;
  title: any;
  parent_id: any;
  order: any;
};

export const GET_CATEGORIES_tree = gql`
  query getCategories {
    getCategories {
      data
      status
    }
  }
`;

export const EDIT_CATEGORIES_tree = gql`
  mutation MyMutation(
    $update_columns: [blog_categories_update_column!]!
    $objects: [blog_categories_insert_input!]!
  ) {
    insert_blog_categories(
      objects: $objects
      on_conflict: {
        constraint: blog_categories_pkey
        update_columns: $update_columns
      }
    ) {
      affected_rows
    }
  }
`;

export const priceSelectOptions = [
  { value: 'highestToLowest', label: 'Az ürüne sahiplik' },
  { value: 'lowestToHighest', label: 'Fazla ürüne sahiplik' },
];
