import { styled } from 'baseui'

export const DefaultPageHeader = styled('header', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '0 1 auto',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '20px',
  /* margin-bottom: 40px, */
  backgroundColor: '#ffffff',
  marginBottom: 30,
  boxShadow: '0 0 8px rgba(0, 0 ,0, 0.1)',

  '@media only screen and (max-width: 990px)': {
    padding: '20px',
  },
}));
  //Orders