import React from 'react';
import PropTypes from 'prop-types';

export type IconProps = {
  color?: string;
  size?: string | number;
} & React.SVGAttributes<SVGElement>;

type SortingIconProps = {
  ascendingColor?: string;
  descendingColor?: string;
} & IconProps;

export function RemoveIcon() {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline cursor-pointer"
    >
      <path
        d="M15 5L5 15"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5L15 15"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const RemoveIcon2: React.FC<IconProps> = ({
  size = '20',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      {...attributes}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33301 5.49054H4.81449H16.6663"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M7.14286 5.49999V3.99999C7.14286 3.60216 7.29337 3.22063 7.56128 2.93932C7.82919 2.65802 8.19255 2.49998 8.57143 2.49998H11.4286C11.8075 2.49998 12.1708 2.65802 12.4387 2.93932C12.7066 3.22063 12.8571 3.60216 12.8571 3.99999V5.49999M15 5.49999V16C15 16.3978 14.8495 16.7793 14.5816 17.0607C14.3137 17.342 13.9503 17.5 13.5714 17.5H6.42857C6.04969 17.5 5.68633 17.342 5.41842 17.0607C5.15051 16.7793 5 16.3978 5 16V5.49999H15Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M8.33203 9.23726V13.4039"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M11.666 9.23726V13.4039"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export function InfoIcon() {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex text-grey-40"
    >
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.6667V8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.33331H8.0075"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const SearchIcon: React.FC<IconProps> = ({
  size = '24',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <title>Search</title>
      <path
        d="M20.4696 21.5303C20.7625 21.8232 21.2373 21.8232 21.5302 21.5303C21.8231 21.2374 21.8231 20.7626 21.5302 20.4697L20.4696 21.5303ZM17.1802 16.1197C16.8873 15.8268 16.4125 15.8268 16.1196 16.1197C15.8267 16.4126 15.8267 16.8874 16.1196 17.1803L17.1802 16.1197ZM18.25 11C18.25 15.0041 15.0041 18.25 11 18.25V19.75C15.8325 19.75 19.75 15.8325 19.75 11H18.25ZM11 18.25C6.99594 18.25 3.75 15.0041 3.75 11H2.25C2.25 15.8325 6.16751 19.75 11 19.75V18.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75V2.25C6.16751 2.25 2.25 6.16751 2.25 11H3.75ZM11 3.75C15.0041 3.75 18.25 6.99594 18.25 11H19.75C19.75 6.16751 15.8325 2.25 11 2.25V3.75ZM21.5302 20.4697L17.1802 16.1197L16.1196 17.1803L20.4696 21.5303L21.5302 20.4697Z"
        fill={color}
      />
    </svg>
  );
};

export const ArrowLeftIcon: React.FC<IconProps> = ({
  size = '16',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M12.667 8H3.33366"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.33331L3.33333 7.99998L8 12.6666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowRightIcon: React.FC<IconProps> = ({
  size = '16',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M3.33301 8H12.6663"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.33331L12.6667 7.99998L8 12.6666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SortingIcon: React.FC<SortingIconProps> = ({
  size = '24',
  color = 'currentColor',
  ascendingColor,
  descendingColor,
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M4.66602 10L7.99935 13.3333L11.3327 10"
        stroke={descendingColor || color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66602 6.00008L7.99935 2.66675L11.3327 6.00008"
        stroke={ascendingColor || color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DuplicateIcon: React.FC<IconProps> = ({
  size = '20px',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M16.0894 8.33333H9.74326C8.9644 8.33333 8.33301 8.96472 8.33301 9.74358V16.0897C8.33301 16.8686 8.9644 17.5 9.74326 17.5H16.0894C16.8683 17.5 17.4997 16.8686 17.4997 16.0897V9.74358C17.4997 8.96472 16.8683 8.33333 16.0894 8.33333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.61538 11.6667H3.91026C3.53623 11.6667 3.17753 11.5181 2.91305 11.2536C2.64858 10.9891 2.5 10.6304 2.5 10.2564V3.91026C2.5 3.53623 2.64858 3.17753 2.91305 2.91305C3.17753 2.64858 3.53623 2.5 3.91026 2.5H10.2564C10.6304 2.5 10.9891 2.64858 11.2536 2.91305C11.5181 3.17753 11.6667 3.53623 11.6667 3.91026V4.61538"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EditIcon: React.FC<IconProps> = ({
  size = '24px',
  color = 'currentColor',
  className = '',
  // attributes?: any = {},
}): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...attributes}
    >
      <path
        d="M9 3H4.55556C4.143 3 3.74733 3.16389 3.45561 3.45561C3.16389 3.74733 3 4.143 3 4.55556V15.4444C3 15.857 3.16389 16.2527 3.45561 16.5444C3.74733 16.8361 4.143 17 4.55556 17H15.4444C15.857 17 16.2527 16.8361 16.5444 16.5444C16.8361 16.2527 17 15.857 17 15.4444V11"
        stroke={color}
        strokeWidth="1.4667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4356 3.43999C14.7173 3.15827 15.0994 3 15.4978 3C15.8962 3 16.2783 3.15827 16.56 3.43999C16.8417 3.72171 17 4.1038 17 4.50221C17 4.90062 16.8417 5.28272 16.56 5.56443L9.8326 12.2919L7 13L7.70815 10.1674L14.4356 3.43999Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CrossIcon: React.FC<IconProps> = ({
  size = '20',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M15 5L5 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5L15 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TrashIcon: React.FC<IconProps> = ({
  size = '24px',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M3.33301 5.49054H4.81449H16.6663"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.14286 5.49999V3.99999C7.14286 3.60216 7.29337 3.22063 7.56128 2.93932C7.82919 2.65802 8.19255 2.49998 8.57143 2.49998H11.4286C11.8075 2.49998 12.1708 2.65802 12.4387 2.93932C12.7066 3.22063 12.8571 3.60216 12.8571 3.99999V5.49999M15 5.49999V16C15 16.3978 14.8495 16.7793 14.5816 17.0607C14.3137 17.342 13.9503 17.5 13.5714 17.5H6.42857C6.04969 17.5 5.68633 17.342 5.41842 17.0607C5.15051 16.7793 5 16.3978 5 16V5.49999H15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33203 9.23726V13.4039"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.666 9.23726V13.4039"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PlusIcon: React.FC<IconProps> = ({
  size = '20',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M10 4.16667V15.8333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16699 10H15.8337"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MinusIcon: React.FC<IconProps> = ({
  size = '16',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M3.33301 8H12.6663"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const XCircleIcon: React.FC<IconProps> = ({
  size = '24px',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 7.5L7.5 12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 7.5L12.5 12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckIcon: React.FC<IconProps> = ({
  size = '24px',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size.toString().replace('px', '')} ${size
        .toString()
        .replace('px', '')}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M13.3334 4L6.00008 11.3333L2.66675 8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.894"
      height="16"
      viewBox="0 0 20.894 16"
    >
      <path
        data-name="_ionicons_svg_ios-checkmark (3)"
        d="M169.184,175.473l-1.708-1.756a.367.367,0,0,0-.272-.116.352.352,0,0,0-.272.116l-11.837,11.925-4.308-4.308a.375.375,0,0,0-.543,0l-1.727,1.727a.387.387,0,0,0,0,.553l5.434,5.434a1.718,1.718,0,0,0,1.135.553,1.8,1.8,0,0,0,1.126-.534h.01l12.973-13.041A.415.415,0,0,0,169.184,175.473Z"
        transform="translate(-148.4 -173.6)"
        fill="currentColor"
      />
    </svg>
  );
};

export const ArrowDownIcon: React.FC<IconProps> = ({
  size = '24px',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M8 3.33331V12.6666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.667 8L8.00033 12.6667L3.33366 8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ChevronDownIcon: React.FC<IconProps> = ({
  size = '20',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M5 12.5L10 7.5L15 12.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export const GripIcon: React.FC<IconProps> = ({
  size = '24px',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M7.49935 10.8337C7.95959 10.8337 8.33268 10.4606 8.33268 10.0003C8.33268 9.54009 7.95959 9.16699 7.49935 9.16699C7.03911 9.16699 6.66602 9.54009 6.66602 10.0003C6.66602 10.4606 7.03911 10.8337 7.49935 10.8337Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49935 4.99967C7.95959 4.99967 8.33268 4.62658 8.33268 4.16634C8.33268 3.7061 7.95959 3.33301 7.49935 3.33301C7.03911 3.33301 6.66602 3.7061 6.66602 4.16634C6.66602 4.62658 7.03911 4.99967 7.49935 4.99967Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49935 16.6667C7.95959 16.6667 8.33268 16.2936 8.33268 15.8333C8.33268 15.3731 7.95959 15 7.49935 15C7.03911 15 6.66602 15.3731 6.66602 15.8333C6.66602 16.2936 7.03911 16.6667 7.49935 16.6667Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4993 10.8337C12.9596 10.8337 13.3327 10.4606 13.3327 10.0003C13.3327 9.54009 12.9596 9.16699 12.4993 9.16699C12.0391 9.16699 11.666 9.54009 11.666 10.0003C11.666 10.4606 12.0391 10.8337 12.4993 10.8337Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4993 4.99967C12.9596 4.99967 13.3327 4.62658 13.3327 4.16634C13.3327 3.7061 12.9596 3.33301 12.4993 3.33301C12.0391 3.33301 11.666 3.7061 11.666 4.16634C11.666 4.62658 12.0391 4.99967 12.4993 4.99967Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4993 16.6667C12.9596 16.6667 13.3327 16.2936 13.3327 15.8333C13.3327 15.3731 12.9596 15 12.4993 15C12.0391 15 11.666 15.3731 11.666 15.8333C11.666 16.2936 12.0391 16.6667 12.4993 16.6667Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const UploadIcon2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="white"
      version="1"
      viewBox="0 0 512 512"
    >
      <path
        d="M2495 4586c-37-17-70-52-84-89-8-20-11-403-11-1230V2065l-332 332c-183 183-346 337-361 342-73 28-158-2-196-69-23-41-28-108-10-143 16-31 958-975 991-993 35-18 101-18 136 0 15 8 242 230 504 493 515 516 500 499 484 588-9 46-62 100-114 115-87 27-92 23-454-338l-328-326-2 1216c-3 1211-3 1217-24 1244-11 15-32 37-46 47-34 25-113 32-153 13z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M454 2016c-38-17-71-57-85-105-7-25-9-148-7-376 4-336 5-341 32-430 69-227 215-400 421-500 179-87 25-80 1745-80s1566-7 1745 80c211 102 365 291 427 520 19 73 22 110 25 425 4 344 4 345-19 389-30 58-65 83-126 89-63 6-120-24-151-78-20-36-21-51-21-346 0-346-7-403-62-507-41-78-127-164-198-199-123-61-39-58-1620-58-1580 0-1493-3-1618 58-76 36-162 123-201 201-54 106-61 161-61 505 0 295-1 310-21 346-39 69-130 98-205 66z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    </svg>
  );
};

export const ExportIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 13V15.6667C17.5 16.0203 17.3361 16.3594 17.0444 16.6095C16.7527 16.8595 16.357 17 15.9444 17H5.05556C4.643 17 4.24733 16.8595 3.95561 16.6095C3.66389 16.3594 3.5 16.0203 3.5 15.6667V13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M14.6673 6.92057L10.5007 2.75391L6.33398 6.92057"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M10.5 2.75391V12.7539"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export const CloseIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.003 10"
      {...props}
    >
      <path
        data-name="_ionicons_svg_ios-close (5)"
        d="M166.686,165.55l3.573-3.573a.837.837,0,0,0-1.184-1.184l-3.573,3.573-3.573-3.573a.837.837,0,1,0-1.184,1.184l3.573,3.573-3.573,3.573a.837.837,0,0,0,1.184,1.184l3.573-3.573,3.573,3.573a.837.837,0,0,0,1.184-1.184Z"
        transform="translate(-160.5 -160.55)"
        fill="currentColor"
      />
    </svg>
  );
};

export const VisibleEye = ({ width = '20px', height = '20px' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.5 10S5.227 4.583 10 4.583 17.5 10 17.5 10s-2.727 5.417-7.5 5.417S2.5 10 2.5 10z"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10 12.074a2.074 2.074 0 100-4.148 2.074 2.074 0 000 4.148z"
      ></path>
    </svg>
  );
};

export const NotVisibleEye = ({
  width = '20px',
  height = '20px',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.568 4.71c.47-.11.95-.166 1.432-.165 4.773 0 7.5 5.455 7.5 5.455a12.622 12.622 0 01-1.473 2.175m-4.582-.73a2.046 2.046 0 11-2.89-2.89m5.495 5.495A6.866 6.866 0 0110 15.454C5.227 15.454 2.5 10 2.5 10a12.58 12.58 0 013.45-4.05l8.1 8.1zM2.5 2.5l15 15"
      ></path>
    </svg>
  );
};

export const NoTrash = ({ size }) => {
  return (
    <svg
      id="svg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0, 0, 400,400"
    >
      <g id="svgg">
        <path
          id="path0"
          d=""
          stroke="none"
          fill="#040404"
          fillRule="evenodd"
        ></path>
        <path
          id="path1"
          d=""
          stroke="none"
          fill="#080404"
          fillRule="evenodd"
        ></path>
        <path
          id="path2"
          d=""
          stroke="none"
          fill="#080404"
          fillRule="evenodd"
        ></path>
        <path
          id="path3"
          d=""
          stroke="none"
          fill="#080404"
          fillRule="evenodd"
        ></path>
        <path
          id="path4"
          d=""
          stroke="none"
          fill="#080404"
          fillRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export const MoreHorizontalIcon: React.FC<IconProps> = ({
  size = '20',
  color = 'currentColor',
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M10 10.75C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.833 10.75C16.2472 10.75 16.583 10.4142 16.583 10C16.583 9.58579 16.2472 9.25 15.833 9.25C15.4188 9.25 15.083 9.58579 15.083 10C15.083 10.4142 15.4188 10.75 15.833 10.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16699 10.75C4.58121 10.75 4.91699 10.4142 4.91699 10C4.91699 9.58579 4.58121 9.25 4.16699 9.25C3.75278 9.25 3.41699 9.58579 3.41699 10C3.41699 10.4142 3.75278 10.75 4.16699 10.75Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

MoreHorizontalIcon.defaultProps = {
  color: 'currentColor',
  size: '24px',
};

MoreHorizontalIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TrashIcon.defaultProps = {
  color: 'currentColor',
  size: '20px',
};

TrashIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
