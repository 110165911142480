import { css } from "@emotion/css";
import { styled } from "baseui";


export const CheckCloseContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  padding: 20,
})

export const ActionContainer = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;

  .data-grid-action {
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      backgroundColor: 
    }

    :last-child {
      margin-right: 0px;
    }
    span {
      display: none;
    } 
  }
`

export const AlignCenter = css`
 text-align: center;
`

export const groupingClassname = css`
  display: flex;
  flex-direction: column;
  block-size: 100%;
  gap: 8px;

  > .rdg {
    flex: 1;
  }
`;

export const optionsClassname = css`
  display: flex;
  gap: 10px;
  text-transform: capitalize;
`;


export const filterColumnClassName = 'filter-cell';

export const filterContainerClassname = css`
  .${filterColumnClassName} {
    line-height: 35px;
    padding: 0;

    > div {
      padding-block: 0;
      padding-inline: 8px;

      &:first-child {
        border-block-end: 1px solid var(--rdg-border-color);
      }
    }
  }
`;

export const filterClassname = css`
  inline-size: 100%;
  padding: 4px;
  font-size: 14px;
  margin-top: 5px;
  padding-left: 10px;
  border-radius: 4px;
  padding-right: 5px;
  box-shadow: 1px 1px 1px 1px #b8b8b8;

  :focus {
    outline: none;
    box-shadow: 1px 1px 1px 1px black;
  }
`;

export const toolbarClassname = css`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  // position: absolute;
  // right: 10px;
  // top: 10px;
`;

export const textCenter = css`
    text-align: center;
`;

export const flexCenter = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`