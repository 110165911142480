import { styled, withStyle } from 'baseui'
import {
  StyledTable as BaseStyledTable,
  StyledHeadCell as BaseStyledHeadCell,
  StyledBodyCell as BaseStyledCell
} from 'baseui/table-grid'
import { Grid, Row as Rows, Col as Column } from 'components/FlexBox/FlexBox'

export const ImageWrapper = styled('div', ({ $theme }) => ({
  width: '40px',
  height: '40px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '20px',
  backgroundColor: $theme.colors.backgroundF7
}))

export const Icon = styled('span', () => ({
  width: '100%',
  height: 'auto'
}))

export const TableWrapper = styled('div', () => ({
  width: '100%',
  height: '450px'
}))

export const StyledTable = withStyle(BaseStyledTable, () => ({
  borderTopLeftRadius: '0 !important',
  borderTopRightRadius: '0 !important',
  borderBottomLeftRadius: '0 !important',
  borderBottomRightRadius: '0 !important',
  alignContent: 'start'
}))

export const StyledHeadCell = withStyle(BaseStyledHeadCell, () => ({
  fontFamily: "'Mulish', sans-serif",
  fontWeight: 700,
  color: '#161F6A !important',
  alignItems: 'center',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  borderTopColor: 'rgba(0, 0, 0, 0.12)',
  borderRightColor: 'rgba(0, 0, 0, 0.12)',
  borderBottomColor: 'rgba(0, 0, 0, 0.12)',
  borderLeftColor: 'rgba(0, 0, 0, 0.12)',
  alignSelf: 'start'
}))

export const StyledCell = withStyle(BaseStyledCell, () => ({
  fontFamily: "'Mulish', sans-serif",
  fontWeight: 400,
  color: '#161F6A !important',
  alignSelf: 'center'
}))

export const StyledHeadCellCenter = withStyle(BaseStyledHeadCell, () => ({
  fontFamily: "'Mulish', sans-serif",
  fontWeight: 700,
  color: '#161F6A !important',
  alignItems: 'center',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  borderColor: 'rgba(0, 0, 0, 0.12)',
  alignSelf: 'start',
  justifyContent: 'center'
}))

export const StyledCellCenter = withStyle(BaseStyledCell, () => ({
  fontFamily: "'Mulish', sans-serif",
  fontWeight: 400,
  color: '#161F6A !important',
  alignSelf: 'center',
  justifyContent: 'center'
}))

export const CategorysRow = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '25px',
  backgroundColor: $theme.colors.backgroundF7,
  position: 'relative',
  zIndex: '1',

  '@media only screen and (max-width: 767px)': {
    marginLeft: '-7.5px',
    marginRight: '-7.5px',
    marginTop: '15px'
  }
}))

export const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',
    ':last-child': {
      marginBottom: 0
    }
  }
}))

export const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px) and (max-width: 991px)': {
    alignItems: 'center'
  }
}))

export const CategoryCardWrapper = styled('div', () => ({
  height: '100%'
}))

export const CategoryCardWrapperOrdering = styled('div', () => ({
  minHeight: '96vh',
  height: '100%',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e6e6e6',
  borderRadius: '4px',
  padding: '20px',
  paddingBottom: '50px',
  backgroundColor: '#fff',
  position: 'relative',
  transition: 'all 0.3s ease-in-out',
  marginTop: '10px',
  marginBottom: '50px',
  overflow: 'hidden',
}))

export const CategoryCardWrapperOrderingSaveButtonArea = styled('div', () => ({
  width: '100%',
  position: 'static',
  right: 50,
  top: 40,
  zIndex: 999,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
}))

export const CategoryLoaderWrapper = styled('div', () => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '20px',
}))

export const CategoryLoaderItem = styled('div', () => ({
  width: '25%',
  padding: '0 15px',
  marginBottom: '30px'
}))