import { useFocusRef } from 'components/DataGrid/hooks/useFocusRef';
import type { GroupFormatterProps } from 'components/DataGrid//types';
import { css } from '@emotion/css'

const groupCellContent = css`
  outline: none;
`;

const groupCellContentClassname = `rdg-group-cell-content ${groupCellContent}`;

const caret = css`
  margin-inline-start: 4px;
  stroke: currentColor;
  strokeWidth: 1.5px;
  fill: transparent;
  vertical-align: middle;

  > path {
    transition: d 0.1s;
  }
`;

export const caretClassname = `rdg-caret ${caret}`;

export function ToggleGroupFormatter<R, SR>({
  groupKey,
  isExpanded,
  isCellSelected,
  toggleGroup
}: GroupFormatterProps<R, SR>) {
  const { ref, tabIndex } = useFocusRef<HTMLSpanElement>(isCellSelected);

  function handleKeyDown({ key }: React.KeyboardEvent<HTMLSpanElement>) {
    if (key === 'Enter') {
      toggleGroup();
    }
  }

  const d = isExpanded ? 'M1 1 L 7 7 L 13 1' : 'M1 7 L 7 1 L 13 7';
  return (
    <span
      ref={ref}
      className={groupCellContentClassname}
      tabIndex={tabIndex}
      onKeyDown={handleKeyDown}
    >
      {groupKey}
      <svg viewBox="0 0 14 8" width="14" height="8" className={caretClassname} aria-hidden>
        <path d={d} />
      </svg>
    </span>
  );
}
