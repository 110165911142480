import React, { useEffect, useState } from "react";
import NoResultSvg from "./no-result.svg";
import { NoResultWrapper, ImageWrapper, ButtonWrapper } from "./NoResult.style";
import Button from "../Button/Button";
import { RefreshIcon } from "assets/icons/RefreshIcon";
import { REACT_APP_API_DEFAULT_IMAGE_URL } from "settings/constants";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Spinner } from "components/Spinner/Spinner";

type NoResultProps = {
  id?: string;
  onClick?: () => void;
  hideButton?: boolean;
  style?: any;
};

const NoResult: React.FC<NoResultProps> = ({
  id,
  onClick = () => window.location.reload(),
  hideButton = true,
  style
}) => {
  const delay = 1;
  const [show, setShow] = useState(false);

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), delay * 1000);
      return () => {
        clearTimeout(timer1);
      };
    }, []);

  return (
    <NoResultWrapper id={id} style={style}>
      {
        show ? (
          <>
            <h3>
              Görüntülemek istediğiniz sayfaya ait veri yok. 😔
            </h3>
            <p><small>Lütfen tekrar deneyin ya da sistem yöneticisi ile iletişime geçin.</small></p>

            <ImageWrapper>
              <LazyLoadImage
                placeholderSrc={NoResultSvg}
                src={NoResultSvg}
                alt="No Result"
                width="100%"
                height="100%"
                style={{
                  transition: 'all 0.5s ease'
                }}
              />
            </ImageWrapper>

            {hideButton ? (
              <ButtonWrapper>
                <div onClick={onClick}>
                  <Button>
                    <RefreshIcon className="mr-1" size="20" /> Tekrar Dene
                  </Button>
                </div>
              </ButtonWrapper>
            ) : null}
          </>
        ) : (
          <Spinner />
        )
      }
    </NoResultWrapper>
  );
};

export default NoResult;
