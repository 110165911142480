import { createContext, useContext } from "react";
import { HeaderRendererProps } from "components/DataGrid";
import { useFocusRef } from "components/DataGrid/hooks";
import { CategoryRow } from ".";

export interface Filter extends Omit<CategoryRow, 'id' | 'complete'> {
  name: any;
  slug: any;
  parent: {
    name: any;
  }
  enabled: boolean;
}

export const FilterContext = createContext<Filter | undefined>(undefined);

export function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.stopPropagation();
  }
}

export function selectStopPropagation(event: React.KeyboardEvent<HTMLSelectElement>) {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation();
  }
}

export function FilterRenderer<R, SR, T extends HTMLOrSVGElement>({
  isCellSelected,
  column,
  children
}: HeaderRendererProps<R, SR> & {
  children: (args: {
    ref: React.RefObject<T>;
    tabIndex: number;
    filters: Filter;
  }) => React.ReactElement;
}) {
  const filters = useContext(FilterContext)!;
  const { ref, tabIndex } = useFocusRef<T>(isCellSelected);

  return (
    <>
      <div>{column.name}</div>
      {filters?.enabled && <div>{children({ ref, tabIndex, filters })}</div>}
    </>
  );
}
